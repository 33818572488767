import classNames from "classnames";
import { Button } from "./Button";
import { Container } from "./Container";
import { GodRays } from "./GodRays/GodRays";

export type HeroProps = {
  heading: string;
  subheading: string;
  socialProofCopy: string;
  logos: string[];
  imageUrl?: string;
  actions: {
    label: string;
    href: string;
    target?: string;
  }[];
  variant: "light" | "dark";
};

export function Hero({
  heading,
  subheading,
  socialProofCopy,
  imageUrl = "/screenshots/ilp.png",
  logos = [],
  actions = [],
  variant,
}: HeroProps) {
  return (
    <Container className="pb-16 pt-20 text-center 2xl:pt-32">
      <div
        className={classNames(
          "absolute inset-0 -top-[20rem] w-full h-[54rem] lg:h-[56rem] -skew-y-12 overflow-hidden z-0",
          variant === "light" &&
            "bg-gradient-to-b from-eggplant-500 to-eggplant-300",
          variant === "dark" &&
            "bg-gradient-to-b from-eggplant-500 to-eggplant-300"
        )}
      >
        <div className="opacity-20 w-full h-full">
          <GodRays />
        </div>
      </div>
      <div className="relative z-1">
        <div className="flex flex-row">
          <div className="sm:w-2/3 flex flex-col text-left">
            <h1 className="max-w-prose font-display font-medium md:leading-[4rem] lg:leading-[5rem] text-white/90 w-[90%] text-[2.25rem] sm:text-[2.75rem] md:text-[3.5rem] lg:text-[4rem] xl:text-[4.5rem] ">
              {heading}
            </h1>
            <p className="mt-6 max-w-2xl text-lg tracking-tight text-eggplant-100">
              {subheading}
            </p>
            <div className="mt-10 flex gap-x-6">
              {actions.map((action, i) => (
                <Button
                  href={action.href}
                  target={action.target}
                  variant={i === 0 ? "solid" : "link"}
                  rel="noreferrer"
                  color="white"
                >
                {action.label}
              </Button>
              ))}
            </div>
          </div>
          <div className="relative">
            <div className="hidden lg:block absolute top-0 left-[8rem] -mt-[4rem] -ml-[10rem]">
              <img
                src={imageUrl}
                role="presentation"
                className="w-full min-w-[768px] shadow-eggplant-900/10"
              />
            </div>
          </div>
        </div>
        <div className="mt-36 lg:mt-56">
          <p className="font-display text-base text-slate-500">
            {socialProofCopy}
          </p>
          <ul
            role="list"
            className="flex items-center justify-center flex-row flex-wrap md:flex-nowrap xl:gap-x-12 xl:gap-y-0"
          >
            {logos.map((logo) => (
              <li key={logo} className="flex w-[140px] md:w-[200px]">
                <img
                  src={logo}
                  width={200}
                  role="presentation"
                  className="grayscale"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
}
